<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">SERVICES</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Add Service</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Service Name</label>
            <input
              v-model="service.name"
              type="text"
              class="form-control"
              placeholder="Enter Service Name"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Service Description</label>
            <textarea
              v-model="service.description"
              type="text"
              class="form-control"
              placeholder="Description"
            ></textarea>
          </div>
          <div>
            <img :src="image1Url" class="img-fluid w-25 d-inline-block" />
          </div>
          <div class="form-group d-inline-block">
            <label for="exampleFormControlFile1">Service Icon</label>
            <input
              @change="setImageOnePreview"
              ref="image1Input"
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
            />
          </div>
          <div>
            <img :src="image2Url" class="img-fluid w-25 d-inline-block" />
          </div>
          <div class="form-group d-inline-block">
            <label for="exampleFormControlFile1">Service Background</label>
            <input
              @change="setImageTwoPreview"
              ref="image2Input"
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
            />
          </div>

          <button
            @click.prevent="saveService"
            class="btn btn-block btn-primary"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "AddBrand",
  data() {
    return {
      image1Url: "/placeholder.png",
      image2Url: "/placeholder.png",
      service: {
        name: "",
        icon: "",
        background: "",
        description: "",
      },
    };
  },
  methods: {
    async setImageOnePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.image1Url = fr.result;
      };
      console.log(this.$refs.image1Input.files[0]);
      const src = fr.readAsDataURL(this.$refs.image1Input.files[0]);
    },
    async setImageTwoPreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.image2Url = fr.result;
      };
      console.log(this.$refs.image2Input.files[0]);
      const src = fr.readAsDataURL(this.$refs.image2Input.files[0]);
    },
    async saveService() {
      try {
        const icon = await this.$store.dispatch(
          "uploadImage",
          this.$refs.image1Input.files[0]
        );

        this.service.icon = icon;

        const background = await this.$store.dispatch(
          "uploadImage",
          this.$refs.image2Input.files[0]
        );

        this.service.background = background;

        const res = await axios.post("/api/v1/services", this.service, {
          headers: {
            authorization: `bearer ${this.$store.state.token}`,
          },
        });

        if (res.status == 201) {
          this.$router.push("/services");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>